import * as React from 'react'

import { InStockLocation } from '@thg-commerce/enterprise-network/src/generated/graphql'
import { GridItem } from '@thg-commerce/gravity-system'
import { TextEntry, TextStyle } from '@thg-commerce/gravity-theme'

import {
  StyledDeliveryIcon,
  StyledDeliveryText,
  StyledGrid,
  StyledStoreIcon,
} from './styles'

export enum WeightGroupsType {
  OneMan = '1-Man',
  OutOfGauge = 'out-of-gauge',
  VendorDirect = 'vendor-direct',
}

export interface FulfilmentMethodIconsRendererProps {
  inStock: boolean
  isCheckStock: boolean
  isOrderInStore: boolean
  enableClickAndCollect?: boolean
  leadTime?: number
  weightGroups?: string[]
  isBookable?: boolean
  inStockLocations?: InStockLocation[]
  iconsAvailability?: {
    homeDelivery: boolean
    storeAvailable: boolean
    storeDelivery: boolean
  }
  deliveryFulfilmentTextStyle?: {
    firstLine: {
      textStyle: {
        entry: TextEntry
        style: TextStyle
      }
    }
  }
  i18nText: {
    clickAndCollect: {
      isAvailable: string
      isNotAvailable: string
    }
    homeDelivery: {
      isAvailable: string
      isNotAvailable: string
      isOutOfStock: string
      datedDelivery?: string
      nextDayDelivery?: string
      oneManDelivery?: string
      outOfGaugeDelivery?: string
      dynamicDelivery?: string
    }
    storeDelivery: {
      isAvailable: string
    }
    orderInStore: {
      isAvailable: string
      message?: string
    }
    pdpDeliveryMessage?: {
      homeDeliveryMsg1Man: string
      homeDeliveryMsgOutOfGauge: string
      storeMsg?: string | null
    }
  }
}

export const FulfilmentMethodIconsRenderer = ({
  isCheckStock,
  isOrderInStore,
  enableClickAndCollect,
  leadTime,
  weightGroups,
  isBookable,
  inStockLocations,
  iconsAvailability,
  i18nText,
  deliveryFulfilmentTextStyle,
}: FulfilmentMethodIconsRendererProps) => {
  if (!iconsAvailability) return null

  const outOfGauge = weightGroups?.includes(WeightGroupsType.OutOfGauge)

  const eligibleForHomeDelivery =
    iconsAvailability?.homeDelivery &&
    inStockLocations?.includes(InStockLocation.Warehouse)

  const eligibleForDatedDelivery =
    leadTime && weightGroups?.includes(WeightGroupsType.VendorDirect)

  const eligibleForNextDayDelivery =
    (outOfGauge || weightGroups?.includes(WeightGroupsType.OneMan)) &&
    isBookable

  const storeDeliveryEnabled =
    iconsAvailability?.storeAvailable || isCheckStock || isOrderInStore

  const nextDayDeliveryEnabled =
    i18nText.pdpDeliveryMessage &&
    eligibleForNextDayDelivery &&
    eligibleForHomeDelivery

  const storePickupEnabled =
    i18nText.pdpDeliveryMessage && iconsAvailability?.storeAvailable

  const noHomeDelivery = !iconsAvailability?.homeDelivery

  const homeDeliveryMessage = () => {
    if (eligibleForHomeDelivery && eligibleForDatedDelivery) {
      return i18nText.homeDelivery.datedDelivery
    }

    if (eligibleForHomeDelivery && eligibleForNextDayDelivery) {
      if (outOfGauge) {
        return i18nText.homeDelivery.outOfGaugeDelivery
      }
      return i18nText.homeDelivery.oneManDelivery
    }

    if (eligibleForHomeDelivery) {
      return i18nText.homeDelivery.dynamicDelivery
    }

    if (noHomeDelivery) {
      return i18nText.homeDelivery.isNotAvailable
    }

    return i18nText.homeDelivery.isOutOfStock
  }

  const clickAndCollectMessage = () => {
    if (eligibleForHomeDelivery && isOrderInStore) {
      return i18nText.orderInStore.isAvailable
    }
    if (isCheckStock) {
      return i18nText.storeDelivery?.isAvailable
    }
    if (iconsAvailability?.storeAvailable) {
      return i18nText.clickAndCollect?.isAvailable
    }
    return i18nText.clickAndCollect?.isNotAvailable
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <StyledGrid data-testid="home-delivery-icon">
        <GridItem>
          <StyledDeliveryIcon available={eligibleForHomeDelivery} />
        </GridItem>
        <GridItem>
          <StyledDeliveryText
            available={eligibleForHomeDelivery}
            firstLine
            firstLineTextStyle={deliveryFulfilmentTextStyle?.firstLine}
          >
            {homeDeliveryMessage()}
          </StyledDeliveryText>
          {nextDayDeliveryEnabled ? (
            <StyledDeliveryText available={eligibleForHomeDelivery}>
              {outOfGauge
                ? i18nText.pdpDeliveryMessage?.homeDeliveryMsgOutOfGauge
                : i18nText.pdpDeliveryMessage?.homeDeliveryMsg1Man}
            </StyledDeliveryText>
          ) : null}
        </GridItem>
      </StyledGrid>
      {enableClickAndCollect && (
        <StyledGrid data-testid="available-in-store-icon">
          <GridItem>
            <StyledStoreIcon available={storeDeliveryEnabled} />
          </GridItem>
          <GridItem>
            <StyledDeliveryText
              style={{ display: 'inline-block' }}
              firstLine
              firstLineTextStyle={deliveryFulfilmentTextStyle?.firstLine}
              available={
                iconsAvailability.storeDelivery || storeDeliveryEnabled
              }
            >
              {clickAndCollectMessage()}
            </StyledDeliveryText>

            {(storePickupEnabled || isOrderInStore) && (
              <StyledDeliveryText available={storeDeliveryEnabled}>
                {storePickupEnabled && iconsAvailability?.storeAvailable
                  ? i18nText.pdpDeliveryMessage?.storeMsg
                  : isOrderInStore
                  ? i18nText.orderInStore.message
                  : null}
              </StyledDeliveryText>
            )}
          </GridItem>
        </StyledGrid>
      )}
    </div>
  )
}
