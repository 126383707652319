import {
  productEventsCategory,
  pushToEventGA4,
} from '@thg-commerce/enterprise-metrics/src/data_layer/pushToDataLayer/utils'

import { removeCurrencySymbol } from '../../../Price'

export enum EventEmitterType {
  SELECT_ITEM = 'select_item',
  SELECT_PROMOTION = 'select_promotion',
}

export const selectedItemEvent = (
  sessionSettings,
  title,
  price,
  brand,
  sku,
  listPageTitle,
  externalIdentifier,
  useExternalIdentifier,
  useExternalIdentifierInSchema,
  defaultVariant,
  productVariant,
  index,
  content,
  widgetTabTitle,
  widgetName,
  alternateProductKeysForCategories,
  categories,
) => {
  return {
    event: 'ecom_event',
    event_name: EventEmitterType.SELECT_ITEM,
    ecommerce: {
      currencyCode: sessionSettings?.currency,
      items: [
        {
          index: index + 1 || 1,
          item_name: title?.value,
          item_id:
            (useExternalIdentifier || useExternalIdentifierInSchema) &&
            externalIdentifier
              ? externalIdentifier
              : sku.toString(),
          price: removeCurrencySymbol(price?.price?.defaultPrice),
          item_brand: brand?.name || '',
          item_list_name: widgetName
            ? widgetTabTitle
              ? `${widgetName} - ${widgetTabTitle}`
              : widgetName
            : listPageTitle
            ? listPageTitle.replace(/.*\"([^\"]*)\"/, '$1')
            : title.value,
          ...productEventsCategory(
            content || categories,
            alternateProductKeysForCategories,
          ),
          item_variant:
            defaultVariant?.externalIdentifier ||
            productVariant?.externalIdentifier ||
            '',
        },
      ],
    },
  }
}

export const selectedPapEvent = (
  sessionSettings,
  title,
  price,
  brand,
  sku,
  listPageTitle,
  externalIdentifier,
  index,
) => {
  return {
    event: 'ecom_event',
    event_name: EventEmitterType.SELECT_PROMOTION,
    promotion_name: 'Offer',
    creative_slot: 'papBanner Component',
    promotion_id: null,
    item: {
      item_id: sku.toString(),
      item_external_id: externalIdentifier
        ? externalIdentifier
        : sku.toString(),
      item_name: title?.value || '',
      item_brand: brand?.name || '',
      item_currency: sessionSettings?.currency,
      index: index?.toString() || '',
      item_page: listPageTitle || title.value,
      price: removeCurrencySymbol(price?.price?.defaultPrice) || '',
      item_promotion: 'Offer',
    },
  }
}

export const eventEmitterGA4 = (
  type,
  sessionSettings,
  title,
  price,
  brand,
  sku,
  listPageTitle,
  externalIdentifier,
  index,
  useExternalIdentifier,
  useExternalIdentifierInSchema,
  defaultVariant,
  productVariant,
  content,
  widgetTabTitle,
  widgetName,
  alternateProductKeysForCategories,
  categories,
) => {
  pushToEventGA4(
    type === EventEmitterType.SELECT_ITEM
      ? selectedItemEvent(
          sessionSettings,
          title,
          price,
          brand,
          sku,
          listPageTitle,
          externalIdentifier,
          useExternalIdentifier,
          useExternalIdentifierInSchema,
          defaultVariant,
          productVariant,
          index,
          content,
          widgetTabTitle,
          widgetName,
          alternateProductKeysForCategories,
          categories,
        )
      : selectedPapEvent(
          sessionSettings,
          title,
          price,
          brand,
          sku,
          listPageTitle,
          externalIdentifier,
          index,
        ),
  )
}
