import * as React from 'react'

import { useSiteConfig, useSiteDefinition } from '@thg-commerce/enterprise-core'
import { COOKIE_GROUP_POWER_REVIEWS } from '@thg-commerce/enterprise-core/src/utils/CookieHelper/types'
import { styled } from '@thg-commerce/enterprise-theme'
import { sanitizeCookie } from '@thg-commerce/enterprise-utils'
import { Direction } from '@thg-commerce/gravity-patterns/ProductBlock/types'

import { ContentItem } from '../../Product'

declare global {
  interface Window {
    pwr: (command: string, config: any) => void | any
  }
}

export enum PowerReviewGroups {
  PROPERTY = 'reviewsGroupingId',
}

export enum PowerReviewComponentType {
  REVIEW_DISPLAY = 'ReviewDisplay',
  REVIEW_SNIPPET = 'ReviewSnippet',
  REVIEW_CATEGORY_SNIPPET = 'CategorySnippet',
  REVIEW_WRITE_REVIEW = 'Write',
}

enum PowerReviewStatus {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}

interface ProductFeed {
  name: string
  url: string
  image_url: string
  description?: string
  brand_name?: string
  upc?: string
  price?: string
  category_name?: string
  in_stock?: string
  variants?: {
    name: string
    image_url: string
    upc?: string
    page_id_variant?: string
  }[]
}

export type PowerReviewProps = {
  externalId: number | string
  locale: string
  sku?: number
  component?: PowerReviewComponentType
  id?: string
  pathname?: string
  powerReviewGroups?: string
  product?: ProductFeed
  mainProduct?: boolean
  powerReviewsAlignment?: Direction
  overrideReviewStyle?: string
}

export function getPowerReviewGroups(content?: ContentItem[]) {
  const powerReviewGroupItem = content?.find(
    ({ key }) => key === PowerReviewGroups.PROPERTY,
  )
  if (powerReviewGroupItem) {
    const powerReviewGroupValue = powerReviewGroupItem.value as {
      __typename: 'ProductContentStringListValue'
      stringListValue: string[]
    }

    if (
      powerReviewGroupValue.stringListValue &&
      powerReviewGroupValue.stringListValue.length > 0
    ) {
      return powerReviewGroupValue.stringListValue[0]
    }
  }
  return
}

interface PowerReviewRenderConfig {
  ENABLE_CLIENT_SIDE_STRUCTURED_DATA: boolean
  api_key: string
  locale: string
  merchant_group_id: string
  merchant_id: string
  page_id: string | number | undefined
  sm_data: string
  enable_front_end_iovation_validation: boolean
  review_wrapper_url: string
  style_sheet?: string
  components: { [componentId in PowerReviewComponentType]?: string }
  product?: ProductFeed
}

const configBuilder = (
  config: PowerReviewRenderConfig,
  product: ProductFeed,
  domain: string,
) =>
  (config.product = {
    name: product.name,
    url: `${domain}${product.url}`,
    image_url: product.image_url,
    description: product?.description || undefined,
    brand_name: product?.brand_name || undefined,
    upc: product?.upc || undefined,
    price: product?.price || undefined,
    category_name: product?.category_name || undefined,
    in_stock: product?.in_stock || undefined,
  })

const configVariantBuilder = (
  config: PowerReviewRenderConfig,
  product: ProductFeed,
) => {
  const variants = product.variants || []
  if (config.product) {
    config.product.variants = variants.map((variant) => ({
      name: variant.name,
      image_url: variant.image_url,
      upc: variant?.upc || undefined,
      page_id_variant: variant?.page_id_variant || undefined,
    }))
  }
}

const powerReviewStatus = (powerReviewValidation) =>
  powerReviewValidation ? PowerReviewStatus.ENABLED : PowerReviewStatus.DISABLED

const powerReviewValidation = (cookieGroupPattern, sanitizedValue) =>
  Boolean(cookieGroupPattern.test(sanitizedValue))

const buildConfig = (
  mainProduct: boolean | undefined,
  enablePowerReviewsSchemaRemoval: boolean | undefined,
  powerReviewApiKey: string | undefined,
  locale: string,
  powerReviewMerchantGroupId: string | undefined,
  powerReviewMerchantId: string | undefined,
  powerReviewGroups: string | undefined,
  externalId: string | number,
  cookieGroupPattern: RegExp,
  sanitizedValue: string | null,
  domain: string,
  pathname: string | undefined,
  sku: number | undefined,
  enableSkuOnPowerReviews: boolean | undefined,
  styleSheetOnPowerReviews?: string | undefined,
) => {
  const config: PowerReviewRenderConfig = {
    locale,
    ENABLE_CLIENT_SIDE_STRUCTURED_DATA: !(
      mainProduct || enablePowerReviewsSchemaRemoval
    ),
    api_key: powerReviewApiKey || '',
    merchant_group_id: powerReviewMerchantGroupId || '',
    merchant_id: powerReviewMerchantId || '',
    page_id: powerReviewGroups ?? enableSkuOnPowerReviews ? sku : externalId,
    sm_data: powerReviewStatus(
      powerReviewValidation(cookieGroupPattern, sanitizedValue),
    ),
    enable_front_end_iovation_validation: powerReviewValidation(
      cookieGroupPattern,
      sanitizedValue,
    ),
    review_wrapper_url: `/addReview.account?pr_return_url=https://${domain}${pathname}&productId=${sku}&powerGroups=${powerReviewGroups}`,
    style_sheet: styleSheetOnPowerReviews ?? '',
    components: {},
  }
  return config
}

const StyledDiv = styled.div<{ overrideReviewStyle?: string }>`
  ${(props) =>
    props.theme.patterns.styledPowerReviews?.displayDifferentStyle &&
    `
    .pr-rating-stars .pr-star-v4 {
    background-image: ${
      props.theme.patterns.styledPowerReviews.backgroundImage
    };
    position: ${props.theme.patterns.styledPowerReviews.position};
    display: ${props.theme.patterns.styledPowerReviews.display};
    width: ${props.theme.patterns.styledPowerReviews.width};
    height: ${props.theme.patterns.styledPowerReviews.height};
    margin-right: ${props.theme.patterns.styledPowerReviews.marginRight};
    background-color: ${props.theme.patterns.styledPowerReviews.inactiveStar};
    clip-path: ${props.theme.patterns.styledPowerReviews?.clipPathStar};
  }
  
    .pr-rating-stars .pr-star-v4-100-filled {
    background-color: ${props.theme.patterns.styledPowerReviews?.starColour};
  }
  
    .pr-rating-stars .pr-star-v4-50-filled {
    background: ${props.theme.patterns.styledPowerReviews.backgroundStarColour};
  }

  .p-w-r .pr-snippet .pr-snippet-stars-png .pr-snippet-rating-decimal {
    display: ${props.theme.patterns.styledPowerReviews.displaySnippet};
  }
  
  .p-w-r .pr-snippet div, .p-w-r .pr-snippet span {
    vertical-align: top;
  }
  
  .p-w-r .pr-snippet-stars-reco-inline .pr-snippet-stars-reco-stars .pr-snippet{
    gap: 4px;
    align-items: center;
    justify-content:  ${
      props.overrideReviewStyle
        ? props.overrideReviewStyle
        : props.theme.patterns?.styledPowerReviews?.displayPosition
    };
  }
  
  .p-w-r .pr-snippet-stars-reco-inline.pr-snippet-minimal .pr-snippet-read-and-write, .p-w-r .pr-snippet-stars-reco-inline.pr-snippet-minimal .pr-snippet-stars-reco-reco {
  margin-top: 0;
  }

    .p-w-r .pr-rd-helpful-text {
      font-size: ${props.theme.patterns.styledPowerReviews.helpfulTextSize};
      font-weight: ${props.theme.patterns.styledPowerReviews.helpfulTextWeight};
    }

    .p-w-r .pr-helpful-btn {
      border: ${props.theme.patterns.styledPowerReviews.helpfulButtonBorder};
    }

    .p-w-r .pr-rd-helpful-action-group button span svg {
      height: ${props.theme.patterns.styledPowerReviews.helpfulButtonHeight};
    }

    .p-w-r .pr-rd-review-headline.pr-h2 {
      font-size: ${props.theme.patterns.styledPowerReviews.headlineTextSize};
      font-weight: ${props.theme.patterns.styledPowerReviews.headlineTextWeigt};
    }

    .pr-rd-header.pr-rd-content-block {
      display: ${
        props.theme.patterns.styledPowerReviews.reviewContentBlockDisplay
      };
      align-items: ${
        props.theme.patterns.styledPowerReviews.reviewContentBlockAlignItems
      };
    }

    button.pr-rd-flag-review-btn {
      color: ${props.theme.patterns.styledPowerReviews.reviewFlagColor};
      text-decoration: ${
        props.theme.patterns.styledPowerReviews.reviewFlagTextDecoration
      };
      font-size: ${props.theme.patterns.styledPowerReviews.reviewFlagFontSize};
    }

    .pr-rd-side-content-block svg g circle {
      fill: ${props.theme.patterns.styledPowerReviews.reviewVerifiedColor};
    }

    .p-w-r .pr-helpful-btn:hover {
      path.pr-thumbs-cuff-fill,
      path.pr-thumbs-fill {
        fill: ${props.theme.patterns.styledPowerReviews.helpfulButtonHoverFill};
      }
    }

    .p-w-r .pr-helpful-btn:focus {
      background-color: ${
        props.theme.patterns.styledPowerReviews
          .helpfulButtonFocusBackgroundColour
      };
      path.pr-thumbs-cuff-fill,
      path.pr-thumbs-fill {
        fill: ${
          props.theme.patterns.styledPowerReviews.helpfulButtonFocusFillColour
        };
      }
    }
    
    .p-w-r .pr-snippet-rating-decimal {
      display: ${
        props.theme.patterns.styledPowerReviews.snippetDecimalRatingDisplay
      }
    }
  `}
`

export const PowerReview = (props: PowerReviewProps) => {
  const {
    powerReviewApiUrl,
    powerReviewApiKey,
    powerReviewMerchantGroupId,
    powerReviewMerchantId,
    enablePowerReviewsSchemaRemoval,
    enableSkuOnPowerReviews,
    styleSheetOnPowerReviews,
  } = useSiteConfig()
  const { domain } = useSiteDefinition()

  const cookieGroupPattern = React.useMemo(() => {
    return new RegExp(`,C000${COOKIE_GROUP_POWER_REVIEWS},`)
  }, [])

  const sanitizedValue = sanitizeCookie('actualOptanonConsent')

  React.useEffect(() => {
    if (
      (enableSkuOnPowerReviews && !props.sku) ||
      (!enableSkuOnPowerReviews && !props.externalId)
    ) {
      return
    }
    window.pwr =
      window.pwr ||
      function () {
        ;(window.pwr.q = window.pwr.q || []).push(arguments)
      }

    const config: PowerReviewRenderConfig = buildConfig(
      props.mainProduct,
      enablePowerReviewsSchemaRemoval,
      powerReviewApiKey,
      props.locale,
      powerReviewMerchantGroupId,
      powerReviewMerchantId,
      props.powerReviewGroups,
      props.externalId,
      cookieGroupPattern,
      sanitizedValue,
      domain,
      props.pathname,
      props.sku,
      enableSkuOnPowerReviews,
      styleSheetOnPowerReviews,
    )

    if (props.component) {
      config.components[props.component] = props.id
      if (props.component === PowerReviewComponentType.REVIEW_SNIPPET) {
        config.components[PowerReviewComponentType.REVIEW_DISPLAY] =
          'pr-review-display'
      }
    }

    if (props.product) {
      configBuilder(config, props.product, domain)

      if (props.product.variants) {
        configVariantBuilder(config, props.product)
      }
    }

    window.pwr('render', config)
  }, [
    domain,
    enablePowerReviewsSchemaRemoval,
    powerReviewApiKey,
    powerReviewMerchantGroupId,
    powerReviewMerchantId,
    cookieGroupPattern,
    sanitizedValue,
    props.component,
    props.externalId,
    props.id,
    props.locale,
    props.mainProduct,
    props.pathname,
    props.powerReviewGroups,
    props.product,
    props.sku,
    enableSkuOnPowerReviews,
    styleSheetOnPowerReviews,
  ])

  if (
    !powerReviewApiUrl ||
    !powerReviewApiKey ||
    !powerReviewMerchantGroupId ||
    !powerReviewMerchantId
  ) {
    return null
  }

  return (
    <StyledDiv
      id={props.id}
      overrideReviewStyle={props.overrideReviewStyle}
    ></StyledDiv>
  )
}
