import * as React from 'react'

import { useFormattableI18nProperty } from '@thg-commerce/enterprise-core'
import { TextStyling } from '@thg-commerce/gravity-patterns'
import { styled } from '@thg-commerce/gravity-patterns/theme'
import { Text } from '@thg-commerce/gravity-theme'

const PricePerUnitContainer = styled.div<{ font?: TextStyling }>`
  ${(props) =>
    props.font
      ? Text(props.font.entry, props.font.style)
      : Text('bodyText', 'default')}
`

export type pricePerUnitText = {
  displayValue?: string
  unit?: string
  subscriptionDisplayValue?: string
} | null
interface PricePerUnitProps {
  pricePerUnit?: pricePerUnitText
  font?: TextStyling
  activeTabIndex?: number
}

export const PricePerUnit = ({
  pricePerUnit,
  font,
  activeTabIndex,
}: PricePerUnitProps) => {
  const i18nText = {
    pricePerUnitText: useFormattableI18nProperty(
      'widget.listpage.priceperunit.text2',
    ),
  }
  if (!pricePerUnit || !pricePerUnit?.displayValue || !pricePerUnit?.unit) {
    return null
  }

  if (activeTabIndex === 1 && pricePerUnit.subscriptionDisplayValue) {
    return (
      <PricePerUnitContainer font={font}>
        {i18nText.pricePerUnitText([
          pricePerUnit.subscriptionDisplayValue,
          pricePerUnit.unit,
        ])}
      </PricePerUnitContainer>
    )
  }

  return (
    <PricePerUnitContainer font={font}>
      {i18nText.pricePerUnitText([
        pricePerUnit.displayValue,
        pricePerUnit.unit,
      ])}
    </PricePerUnitContainer>
  )
}
